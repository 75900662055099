import * as React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import '../style.css'

import SEO from "../components/seo"

const IndexPage = () => (
  <div className="layout" style={{backgroundColor:'black',minWidth:'100vw',minHeight:'100vh'}}>
    <h1> Yaygara Design</h1>
  </div>
)

export default IndexPage
